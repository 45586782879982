<template>
  <div class="object-panel">
    <div
      v-if="showPanel"
      class="close-icon-container"
    >
      <div class="d-flex justify-space-between close-icon-container-header">
        <div class="pl-1 subtitle-1 white--text">Объекты</div>
        <v-icon
          color="white"
          small
          class="close-icon justify-end"
          @click="toggleObjectPanel"
        >mdi-close
        </v-icon>
      </div>
    </div>
    <v-icon
      v-else
      color="white"
      large
      class="toggle-button"
      @click="toggleObjectPanel"
    >mdi-format-list-bulleted-square
    </v-icon>
    <div
      class="object-panel-content"
      v-if="objects.length"
    >
      <v-row
        class="object-panel-buttons"
        v-for="(item, index) in objects"
        :key="index + ', ' + localKey"
        dense
      >
        <v-col
          cols="6"
          class="btn-block"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                :color="item.eavEntity.style.color"
                class="icon-style"
              >{{ item.eavEntity.icon }}
              </v-icon>
            </template>
            <span>{{ item.eavEntity.entityName }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="reports[item.eavEntity.id] && reports[item.eavEntity.id].length"
          >
            <template #activator="{ on }">
              <div
                v-on="on"
                class="counter"
              >
                {{ reports[item.eavEntity.id].length }}
              </div>
            </template>
            <span>Количество выбранных объектов</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="$can('admin') || $can('unit') || $can('manager') || $can('auditor')"
          >
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                small
                class="icon-style"
                @click="createItem(item)"
                :disabled="isDisableCreate(item)"
              >mdi-plus
              </v-icon>
            </template>
            <span>Создать экземпляр</span>
          </v-tooltip>

          <v-tooltip
            bottom
            :disabled="isDisableInteraction"
          >
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                small
                class="icon-style"
                @click="hideItem(item, showObjects[item.eavEntity.id])"
                :disabled="isDisableInteraction"
              >{{ showObjects[item.eavEntity.id] ? 'mdi-eye' : 'mdi-eye-off' }}
              </v-icon>
            </template>
            <span> {{ showObjects[item.eavEntity.id] ? 'Скрыть' : 'Показать' }} объекты этого типа</span>
          </v-tooltip>

          <v-tooltip
            bottom
            :disabled="isDisableInteraction"
          >
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                small
                class="icon-style"
                @click="showFilters(item)"
                :disabled="isDisableInteraction"
              >mdi-filter
              </v-icon>
            </template>
            <span>Фильтр</span>
          </v-tooltip>

          <v-tooltip
            bottom
            :disabled="isDisableInteraction"
            v-if="canClearFilter(item)"
          >
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                small
                class="icon-style"
                @click="resetFilter(item)"
                :disabled="isDisableInteraction"
              >mdi-restart
              </v-icon>
            </template>
            <span>Отчистить фильтр</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                small
                class="icon-style"
                @click="openTableDialog(item)"
                :disabled="isDisableInteraction"
              >mdi-table-large
              </v-icon>
            </template>
            <span>Таблица объектов</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="$can('admin') || $can('unit') || $can('manager')"
          >
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                small
                class="icon-style"
                @click="openImportDialog(index)"
                :disabled="isDisableInteraction"
              >mdi-file-import
              </v-icon>
            </template>
            <span>Импорт данных</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                :disabled="isDisableInteraction || !showObjects[item.eavEntity.id]"
                @click="blockLayer(item, blockedObjects[item.eavEntity.id])"
                class="icon-style"
                small
              >
                {{ blockedObjects[item.eavEntity.id] ? 'mdi-lock-outline' : 'mdi-lock-open-outline' }}
              </v-icon>
            </template>
            <span>Блокировка слоя</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                :disabled="isDisableInteraction || !showObjects[item.eavEntity.id]"
                @click="handleItemOffset(item)"
                class="icon-style"
                small
              >
                {{ 'mdi-selection-drag' }}
              </v-icon>
            </template>
            <span>Массовое перемещение</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <BaseDialog
      with-label
      max-width="700"
      v-model="isOffsetDialog"
      label="Смещение"
      is-confirm-btn
      text-confirm="сместить"
      is-cross-close
      @confirm="massMove()"
      :retain-focus="false"
    >
      <template #content>
        <div>
          <p class="orange--text ml-1">Использовать с осторожностью</p>
          <v-text-field
            v-model="offsetX"
            label="сдвиг по широте"
            hide-details
            height="30"
            class="mt-3 mb-2"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="offsetY"
            label="сдвиг по долготе"
            height="30"
            class="mt-1 mb-2"
            outlined
            dense
            hide-details
          ></v-text-field>
        </div>
        <v-select
          class="mt-1"
          height="30"
          v-if="typesGeometry"
          dense
          :items="typesGeometry"
          v-model="typeOffsetGeometry"
          item-text="label"
          item-value="value"
          outlined
        ></v-select>
        <div>
          <BaseBtn
            color="primary"
            depressed
            :title="'Сохранить объекты: ' + typesGeometry.find(g => g.value === typeOffsetGeometry).label"
            @click="saveMassMoved"
          />
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import reportCart from '@/components/mixins/map/reportCart'
import BaseBtn from '@/components/base/UI/BaseBtn'
import _ from 'lodash'
import BaseDialog from '@/components/base/BaseDialog'

export default {
  components: { BaseDialog, BaseBtn },
  mixins: [reportCart],
  name: 'MapObjectPanel',
  props: {
    passport: Object,
    filter: Object,
    objects: Array,
    isDisableInteraction: Boolean,
    currentIdCreatingMapObject: {
      default: null
    }
  },
  data () {
    return {
      showPanel: true,
      localKey: 0,
      isOffsetDialog: false,
      offsetX: 0.00001,
      offsetY: 0.00001,
      typesGeometry: [
        { value: 'Point', label: 'Точки' },
        { value: 'Polygon', label: 'Полигоны' },
        { value: 'LineString', label: 'Линии' },
        { value: 'all', label: 'Все' }],
      typeOffsetGeometry: 'Point',
      currentItemOffset: null
    }
  },
  computed: {
    countReportGroups () {
      return this._countReportGroups(this.passport.properties.passportEntities)
    },
    reports () {
      return this.$store.getters['map/cart']
    },
    blockedObjects: {
      get () {
        return this.$store.getters['map/blockedObjects']
      },
      set ({ id, status }) {
        this.$store.commit('map/blockedObjects', { id, status })
      }
    },
    showObjects: {
      get () {
        return this.$store.getters['map/showObjects']
      },
      set ({ id, status }) {
        this.$store.commit('map/showObjects', { id, status })
      }
    }
  },
  watch: {
    objects: {
      handler () {
        this.objects.forEach((item) => {
          !this.showObjects?.hasOwnProperty(item.eavEntity.id) ? (this.showObjects = {
            id: item.eavEntity.id,
            status: true
          }) : ''
          !this.blockedObjects?.hasOwnProperty(item.eavEntity.id) ? (this.blockedObjects = {
            id: item.eavEntity.id,
            status: false
          }) : ''
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    canClearFilter (item) {
      return this.filter[item.eavEntity.id] && _.size(this.filter[item.eavEntity.id]) > 1
    },
    toggleObjectPanel () {
      let panel = document.querySelector('.object-panel-content')
      if (panel) {
        panel.classList.toggle('hidden')
      }
      this.showPanel = !this.showPanel
    },
    isDisableCreate (item) {
      return this.currentIdCreatingMapObject !== item.id && typeof this.currentIdCreatingMapObject === 'number' ||
        !this.currentIdCreatingMapObject && this.isDisableInteraction ||
        !this.showObjects[item.eavEntity.id]
    },
    openTableDialog (item) {
      this.$emit('reportDialog', item)
    },
    openImportDialog (item) {
      this.$emit('importDialog', item)
    },
    resetFilter (item) {
      this.localKey += 1
      this.$emit('resetFilter', item)
    },
    showFilters (item) {
      this.$emit('showFilters', item)
    },
    createItem (data) {
      if (!this.blockedObjects[data.eavEntity.id]) {
        this.$emit('geometry', { item: data, isCreate: true, type: 'object' })
      }
    },
    hideItem (item, status) {
      if (this.blockedObjects[item.eavEntity.id]) { //если заблокировано
        this.blockLayer(item)
      }
      this.showObjects = { id: item.eavEntity.id, status: !this.showObjects[item.eavEntity.id] }
      this.$emit('hideItem', { item, status })
    },
    blockLayer (item) {
      this.blockedObjects = { id: item.eavEntity.id, status: !this.blockedObjects[item.eavEntity.id] }
      this.$emit('blockLayer', { item, status: this.blockedObjects[item.eavEntity.id] })
    },
    handleItemOffset (item) {
      this.currentItemOffset = item
      this.isOffsetDialog = true
    },
    saveMassMoved () {
      this.$emit('saveMassMoved', {
        typeOffsetGeometry: this.typeOffsetGeometry === 'all' ? ['LineString', 'Polygon', 'Point'] : [this.typeOffsetGeometry],
        item: this.currentItemOffset
      })
    },
    massMove () {
      this.$emit('massMove', {
        item: this.currentItemOffset,
        offsetX: this.offsetX,
        offsetY: this.offsetY,
        typeOffsetGeometry: this.typeOffsetGeometry === 'all' ? ['LineString', 'Polygon', 'Point'] : [this.typeOffsetGeometry]
      })
      this.isOffsetDialog = false
    }
  }
}
</script>

<style>
  .object-panel {
    padding: 8px;
    display: flex;
    flex-direction: column;
    z-index: 1001 !important;
    position: absolute;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    bottom: 100px;
    right: 5%;
    background: #464847;
    border: 1px solid #000000;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
  }
</style>

<style scoped>
  .close-icon-container {
    padding: 8px 8px 0 8px;
  }
  .close-icon-container-header {
    width: 100%;
    position: relative;
  }
  .close-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  .close-icon:hover {
    background-color: #646766;
    border: #F5F5F5 1px solid;
    transition: none;
  }
  .hidden {
    display: none;
  }
  .toggle-button {
    border: none;
    cursor: pointer;
  }
  .object-panel-buttons {
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 8px 8px; /* Поменял отступ с права с 16 на 0 */
  }
  .btn-block {
    display: flex;
    gap: 12px;
  }
  .icon-style {
    color: #F5F5F5;
    width: 18px;
    height: 18px;
  }
  .counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 18px;
    height: 18px;
    padding: 8px;
    border: none;
    background: #F5F5F5;
    border-radius: 50%;
  }
  .object-panel-content {
    padding: 8px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 400px;
  }
</style>

